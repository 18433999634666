<template>
  <div class="contanter_pay">
    <headerTab></headerTab>
    <div class="c_content">
      <div class="rule_pay">*请按要求回寄合同须知</div>
      <div class="c_text">1.单份工分赠予合同不足转让额度，可以选择多份合同，保证回寄的合同所持有工分大于等 于转让数额</div>
      <div class="c_text">2.回寄所需的合同为工分份合同，请仔细核对保持一致，在进行操作，避免转让失败。 </div>
      <div class="c_text">3.物流信息按邮寄的物流信息填写，切勿随意填写</div>
      <div class="select_input" v-if="!tableData.trade.return_contract">请选择合同:</div>
      <van-checkbox-group v-model="checked" direction="horizontal" v-if="!tableData.trade.return_contract">
        <van-checkbox style="margin-top:20px;margin-left:10px" icon-size="10px" v-for="(item,index) in dataList" :key="index" @click="selectBtn(item,index)" :name="item.contract_id">
          <div  :class="item.checked?'c_select_confim':'c_select'">
            <div class="checkout1">{{item.contract_name}}</div>
            <div class="checkout">股份:{{item.contract_shares}}~合同编号{{item.contract_no}}</div>
          </div>
        </van-checkbox>
      </van-checkbox-group>
      <div v-if="tableData.trade.return_state==1" style="margin-top:30px">
      <div class="addressText">收件地址</div>
        <div class="shpace">
          <div>收件人:{{tableConfig.shares_sales_receiver}}</div>
          <div>联系方式:{{tableConfig.shares_sales_receiver_mobile}}</div>
          <div>收件地址:{{tableConfig.shares_sales_receiver_address}}</div>
        </div>
      <div class="addressText">邮寄信息</div>
        <div v-if="tableData.trade.return_contract">
            <div class="contract_select" style="font-size:16px">已选择合同：</div>
            <div class="contract_select" v-for="(item,index) in tableData.trade.return_contract" :key="index">
              <div>{{index+1}},{{item.contract_name}}</div>
              <div class="contranct_next">股份：{{item.contract_shares}}</div>
              <div class="contranct_next">合同编号：{{item.contract_no}}</div>
            </div>
        </div>
        
        <div class="input_list">
          <div>物流公司:</div>
          <input v-model="dataForm.company.value" placeholder="" />
        </div>
        <div class="input_list">
          <div>物流单号:</div>
          <input @input="test" v-model="dataForm.order_sn.value" placeholder="" />
        </div>
      </div>

      <div class="submit_pay" @click="submit">确认提交</div>
    </div>

  </div>
</template>
<script>
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import { shipments_api, getShipmentsList_api } from "@/api/admin";
import { dealFromList_api, returnList_api, getConfig_api } from "@/api/deal";
import { Toast } from "vant";
// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      checked: [],
      dataList: [],
      contentList: [],
      tableData: {
        trade: {}
      },
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        }
      },
      tableConfig: null,
      companyCode: "",
      shareData: ""
    };
  },
  created() {
    document.title = "信息填写";
    this.getDealList();
    this.queryList = this.$route.query;
    // if(this.queryList.audit_id){
    this.getDetails(this.queryList.id);
    this.getConfig();
  },
  components: {
    headerTab
  },
  methods: {
    test() {
      let data = {
        LogisticCode: this.dataForm.order_sn.value
      };
      getShipmentsList_api(data).then(res => {
        if (res.code == 0) {
          this.companyCode = res.data.Shippers[0].ShipperCode;
        }
      });
    },
    getConfig() {
      let data = {
        config: [
          "shares_sales_receiver",
          "shares_sales_receiver_mobile",
          "shares_sales_receiver_address"
        ]
      };
      getConfig_api(data).then(res => {
        console.log(res.data);
        this.tableConfig = res.data;
      });
    },
    selectBtn(e, index) {
      if (this.dataList[index].checked) {
        this.dataList[index].checked = false;
      } else {
        this.dataList[index].checked = true;
      }
      this.$forceUpdate();
    },
    getDealList() {
      let data = {
        contract_type: 2
      };
      dealFromList_api(data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
          this.dataList.forEach(e => {
            e.checked = false;
          });
        }
      });
    },
    async getDetails(id) {
      let res = await apiCommon.SellingDeal_api(id);
      if (res.code == 0) {
        this.tableData = res.data;
        this.$forceUpdate();
      }
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    submit() {
      if (this.tableData.trade.return_state == 0) {
        // 选择合同
        this.selectContract();
      } else {
        // 合同回寄
        this.returnContract();
      }
    },
    selectContract() {
      this.shareData = "";
      this.dataList.forEach(e => {
        if (e.checked) {
          if (e.contract_state != 0) {
            this.contentList.push(e);
            this.shareData += Number(e.contract_shares);
          }
        }
      });
      if (this.shareData < this.tableData.shares) {
        Toast({
          message: "已选合同不足以抵扣股份",
          icon: "error",
          duration: 1000
        });
        return false;
      }
      let data = {
        return_contract: this.contentList
      };
      returnList_api(data, this.tableData.trade.trade_id).then(res => {
        if (res.code == 0) {
          Toast({
            message: "提交成功",
            icon: "error",
            duration: 1000
          });
          this.getDetails(this.queryList.id);
        }
      });
    },
    returnContract() {
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          return false;
        }
      }
      if(!this.dataForm.company.value){
        Toast({
            message: "请输入公司",
            icon: "error",
            duration: 1000
          });
          return false
      }
       if(!this.dataForm.order_sn.value){
        Toast({
            message: "请输入快递单号",
            icon: "error",
            duration: 1000
          });
          return false
      }
      let data = {
        audit_id: this.tableData.trade.trade_id,
        audit_type: 7,
        shipments_info: {
          Shipper: this.dataForm.company.value,
          LogisticCode: this.dataForm.order_sn.value,
          ShipperCode: this.companyCode,
          address: this.tableConfig.shares_sales_receiver_address,
          name: this.tableConfig.shares_sales_receiver,
          phone: this.tableConfig.shares_sales_receiver_mobile
        }
      };
      console.log(data, this.queryList, "data");
      shipments_api(data).then(res => {
        if (res.code == 0) {
          Toast({
            message: "提交成功",
            icon: "error",
            duration: 1000
          });
          this.getDetails(this.queryList.id);
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>

<style>
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
  line-height: 50px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.van-checkbox-group--horizontal {
  display: inline;
}
input {
  font-size: 25px;
}
.messageTitle_pay .messageIcon_pay {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.signtureShow {
  position: fixed;
  left: 30px;
  top: 100px;
  width: 645px;
  height: 900px;
  background-color: #fff;
}
.messageTitle_pay {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: -13px;
  right: 20px;
  color: #000;
}
.contanter_pay {
  width: 100%;
  position: relative;
  padding: 0px 40px 0;
  box-sizing: border-box;
}
.addressText {
  color:#0F87FF;
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 20px
}
.rule_pay {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  /* line-height: 35px; */
  color: #ff3e46;
  /* margin-top: 100px */
}
.message_pay {
  font-size: 24px;
  color: #33a1fd;
  margin: 20px auto 42px;
}
.select_input,
.shpace {
  font-size: 25px;
  color: #2e2e30;
}
.select_input {
  font-size: 35px;
  color: #0f87ff;
  margin-top: 35px;
  font-weight: 800;
}
.c_select {
  border-bottom: 1px solid #0f87ff;
  padding-bottom:10px;
  margin-left:5px;
  width:500px
}
.c_select_confim{
  border-bottom: 1px solid #0f87ff;
  padding-bottom:10px;
  margin-left:5px;
  width:500px
}
.c_select_confim div{
  margin-top: 5px
}
.c_select div{
  margin-top: 5px
}
.c_select .checkout1 {
  font-size: 24px;
  line-height: 30px;
  color: #ababab;
}
.c_select .checkout {
  font-size: 18px;
  line-height: 30px;
  color: #ababab;
}
.c_select_confim .checkout {
  font-size: 18px;
  line-height: 30px;
  color: #1C1B1B;
}
.c_select_confim .checkout1 {
  font-size: 24px;
  line-height: 30px;
  color: #0F87FF;
}
.shpace {
  margin-left: 31px;
}
.shpace > div {
  margin-bottom: 24px;
}
.input_list {
  display: flex;
  margin-top: 70px;
}
.input_list input {
  width: 400px;
  height: 60px;
  border: 2px solid #d6d6d6;
  opacity: 1;
  border-radius: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
.input_list div {
  margin-right: 15px;
  font-size: 22px;
  line-height: 60px;
}
.submit_pay {
  width: 388px;
height: 90px;
background: #DEB77A;
opacity: 1;
border-radius: 50px;
  text-align: center;
  line-height:90px;
  background-color: #deb77a;
  color: #fff;
  font-size: 35px;
  margin: 50px auto 0;
}
.c_content {
  margin: 30px 25px;
}
.c_text {
  font-size: 25px;
  color: #1c1b1b;
  margin: 20px 0;
}
.contract_select{
  font-size:25px;
  margin-bottom: 20px
}
.contranct_next{
  font-size: 25px;
  text-indent:2em;
  margin-top: 20px
}
</style>

